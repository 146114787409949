import React, { useState, useEffect, useContext } from "react";
import { db2 } from "../database/Firebase";
import { collection, getDocs } from "firebase/firestore";

const Products = ({ items }) => {
  const [open, setOpen] = useState(false);
  const [postOpen, setPostOpen] = useState("customModal");
  const [cardData, setCardData] = useState(null);
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [galleryData, setGalleryData] = useState([]);
  const [albumData, setAlbumData] = useState([]);
  const [active, setActive] = useState(false);
  const [userPlaylist, setUserPlaylist] = useState({});
  //const { message, setMessage } = useContext(Message_data);
  //const router = useRouter();

  // const sendData = (trackData) => {
  //   setActive(true);
  //   setMessage(trackData);
  // };

  // const pause = () => {
  //   setActive(false);
  //   setMessage(JSON.stringify({ pause: true }));
  // };

  const onOpenModal = (item) => {
    //console.log("item", item);
    setOpen(true);
    setTitle(item.title);
    setCoverImage(item.coverImage);
    setCardData(JSON.stringify(item));
    setTimeout(() => {
      //console.log("change class name to make animation");
      setPostOpen("customModal postopen");
    }, 1000);
  };

  const onCloseModal = () => {
    setPostOpen("customModal");
    setOpen(false);
    setCardData(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(
        collection(db2, "users/Bs7gqBrNUtMBmM7jgEyGmCKtSNl1/ecommerce")
      );
      querySnapshot.forEach((doc) => {
        // get the audio url for the displyed card
        let url = doc
          .data()
          .customFieldList.find(
            (customField) => customField.customName === "audioURL"
          );
        if (url && url["customValue"]) {
          url = url["customValue"];
        }

        // get the artist name
        let artistname = doc
          .data()
          .customFieldList.find(
            (customField) => customField.customName === "artist"
          );
        if (artistname && artistname["customValue"]) {
          artistname = artistname["customValue"];
        }

        // filter out only releases (products with release in the tags)
        let release = doc
          .data()
          .tags.split(",")
          .map(function (item) {
            return item.trim();
          });
        const released = release.findIndex((item) => item == "merch");
        if (released != -1) {
          setGalleryData((oldData) => [
            ...oldData,
            {
              product_name: doc.data().product_name,
              releasePath: doc.data().releasePath, //doc.data().product_name.replace(/\s+/g, '-').toLowerCase(),
              artist: artistname,
              coverImage: doc.data().imageList[0].url,
              url: url,
              tags: doc.data().tags.split(","),
              userplaylist: "false",
              price: doc.data().price,
            },
          ]);
        }
      });
    };
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    //console.log("items is now", items);
  }, [items]);

  return (
    <>
      <section className="shop-container">
        <div className="">
          {galleryData.length == 0 ? <div className="shoplist">loading shop ...</div>
            :
            <div className="shoplist">

              {galleryData.map((item, index) => (
                <div className="shoplist__item" key={index}>
                  {/* <a href={"/releases/" + item.releasePath}> */}
                  <div
                    className="shoplist__item__img"
                    style={{
                      background: `url('${item.coverImage}') center center`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  {/* </a> */}
                  <div className="shoplist__item__details">{item.product_name}</div>

                  <div style={{ fontWeight: "900" }}>USD$ {item.price}</div>

                  <div className="content-block contact-buttons">
                    <a href="#" class="btn btn-l btn-gradient" >
                      <span className="btn-border"></span>
                      <span className="btn-hover"></span>
                      <span className="btn-caption shoplist__item__view" data-price={item.price} data-release-path={item.releasePath}>VIEW</span>
                    </a>
                  </div>

                </div>
              ))}

            </div>
          }

        </div>
      </section>
    </>
  );
};

export default Products;
