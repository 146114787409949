import logo from './logo.svg';
import './App.scss';
import Products from "./components/Products";

function App() {
  return (
    <>
      <Products/>
    </>
  );
}

export default App;
